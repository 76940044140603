body {
    background-color: #ffffff;
    background-size: auto;
    font-size: 12.5pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 80%;
    box-shadow: 0px 0px 20px #000000;
    margin: auto;
}

a {
    color: #ffffff;
}

a:hover {
    color: #a1f2fd;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.btn {
    background-color: #134069;
    border-color: #ffffff;

}

.btn:hover {
    background-color: #2264a1;
    border-color: #ffffff;
    box-shadow: 0px 0px 5px #000000;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.bg-azdark {
    /*   background-image: linear-gradient(to right, #ffffff, #242424, #ffffff);
    box-shadow: 0px 0px 10px #585858;*/
    border-top: 1px #ffffff solid;
    border-bottom: 1px #ffffff solid;
    background-color: #2264a1;
    /*#282c34*/
    width: 80%;
    margin: auto;
}

.dropdown-menu {
    background-color: #2264a1;
    padding: 0px;
    border: 1px solid #ffffff;
    box-shadow: 5px 5px 10px #525252;
}

.nav-link:hover {
    background-color: red;
}

.dropdown-item {
    color: #ffffff;
    padding: 15px;
}

.dropdown-item:hover {
    color: #ffffff;
    background-color: #074c8d;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: #ffffff;
    background-color: #0958a1;
}

.navbar-light .navbar-nav .nav-link {
    color: #ffffff;
    background-color: #2264a1;
}

.dropdown-divider {
    background-color: #ffffff;
    margin: 0px;
}

.homepage-image {
    border-bottom: 1px solid #fff;
    background-repeat: no-repeat;
    background-position-x: 00%;
    background-position-y: 0%;
    background-image: linear-gradient(120deg, #4785b8, #4785b8, #9bd2ff, #4785b8);
    color: #fff;
    padding: 120px 0px 100px 10%;
    background-image: url('../src/images/background.jpg');
    background-size: cover;

}

.gov-image {
    border-bottom: 1px solid #fff;
    background-repeat: no-repeat;
    background-image: linear-gradient(120deg, #4785b8, #4785b8, #9bd2ff, #4785b8);
    color: #fff;
    padding: 120px 0px 100px 10%;
    background-image: url('../src/images/PPStrategies.jpg');
    background-size: cover;
}

.dev-image {
    border-bottom: 1px solid #fff;
    background-repeat: no-repeat;
    background-position-x: 15%;
    background-position-y: 45%;
    color: #fff;
    padding: 120px 0px 100px 10%;
    background-image: url('../src/images/CustomApp.png');
    background-color: #000000;
    background-size: 150% 150%;
}

.sup-image {
    border-bottom: 1px solid #fff;
    background-repeat: no-repeat;
    background-position-x: 15%;
    background-position-y: 45%;
    color: #fff;
    padding: 120px 0px 100px 10%;
    background-image: url('../src/images/SupportBack.png');
    background-color: #000000;
    background-size: 150% 150%;
}

.con-image {
    border-bottom: 1px solid #fff;
    background-repeat: no-repeat;
    background-position-x: 15%;
    background-position-y: 45%;
    color: #fff;
    padding: 120px 0px 80px 10%;
    background-image: url('../src/images/contact-us.jpeg');
    background-color: #000000;
    background-size: cover
}

.intro {
    padding: 30px 10% 30px 10%;
    text-align: center;
    background-color: #2264a1;
    color: #ffffff;
}

.con-intro {
    padding: 20px 5% 20px 5%;
    text-align: center;
    background-color: #2264a1;
    color: #ffffff;
}

.columnContent {
    padding: 20px 20px 20px 20px;
    margin: 20px 20px 20px 20px;
    text-align: Left;
}

.row {
    margin: 0;
}

.column {
    padding: 30px;
    background-color: #ffffff;
}

.gov {
    padding: 30px;
    background-color: #ffffff;
    float: left;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 5px #808080;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-wrap: wrap;
    border-radius: 10px;
    width: auto;
}

.colHeader {
    height: 60px;
    padding-top: 10px;
}

.alternate {
    background-color: #2264a1;
    color: #ffffff;
}

img {}

.imgDiv {
    float: left;
    width: 30%;
    margin: 20px;
}

.contentDiv {
    float: left;
    margin: 20px;
    width: 60%;
}

.alternate .imgDiv {
    float: right;
    width: 30%;
    margin: 20px;
}

.alternate .contentDiv {
    float: right;
    margin: 20px;
    width: 60%;
}

img.home {
    width: 100%;
    box-shadow: 0px 0px 15px #808080;
    border-radius: 4px;
    border: #ffffff solid 1px;
}

.footerMob {
    font-size: 9pt;
    padding-top: 5px;
    text-align: right;
    padding-right: 10px;
}

@media only screen and (min-width:321px) and (max-width: 768px) {
    body {
        width: 100%;
    }

    .footerMob {
        font-size: 9pt;
    }

    .bg-azdark {
        width: 100%;
    }

    .homepage-image {
        border-bottom: 1px solid #ffffff;
        background-repeat: no-repeat;
        min-height: 250px;
        color: #fff;
        padding: 98px 0px 50px 15%;
    }

    .gov-image {

        padding: 100px 0px 50px 5%;
        background-image: url('../src/images/PPStrategies.png');
        background-size: cover;
    }

    .dev-image {
        padding: 100px 0px 50px 5%;
        background-image: url('../src/images/CustomApp.png');
        background-color: #000000;
        background-size: cover
    }

    .sup-image {
        padding: 100px 0px 50px 5%;
        background-image: url('../src/images/SupportBack.png');
        background-size: cover;
    }

    .con-image {
        padding: 100px 0px 50px 5%;
        background-image: url('../src/images/contact-us.jpeg');
        background-size: cover;
    }

    h2 {
        font-size: 1.2em
    }

    .imgDiv {
        width: 80%;
        margin: 20px;
    }

    .contentDiv {
        margin: 20px;
        width: 80%;
    }

    .alternate .imgDiv {
        float: left;
        width: 80%;
        margin: 20px;
    }

    .alternate .contentDiv {
        float: left;
        width: 80%;
        margin: 20px;
    }

    .row {
        margin-right: 0px;
    }

    .row>* {
        padding-right: 0px;
    }

}